:root {
  --primary: #f101a2;
  --faded: #888888;
  --rotate: 0deg;
  --bg-radial-color-0: #ffffff;
  --bg-radial-color-1: #fff2fb;
  --bg-radial-color-2: #ffe3f6;
  --bg-radial-color-3: #fcc4ea;
  --bg-radial-color-4: #ff66ce;
  --prog: 0%;
  --side: calc(max(1vw, 1vh) * -40);
}

html {
  --bg-spin-duration: 180s;
  max-width: 100vw;
}

@keyframes vslow-rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.radial-background-container {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 100vw;
  max-height: 100vh;
  z-index: -4;
}

.radial-background {
  position: fixed;
  z-index: -3;
  top: var(--side);
  right: var(--side);
  bottom: var(--side);
  left: var(--side);
  animation: var(--bg-spin-duration) vslow-rotate infinite linear;
  background-image: repeating-conic-gradient(
    from var(--rotate),
    var(--bg-radial-color-1) calc(0 *6deg) calc(1 *6deg),
    var(--bg-radial-color-4) calc(1 *6deg) calc(2 *6deg),
    var(--bg-radial-color-1) calc(2 *6deg) calc(3 *6deg),
    var(--bg-radial-color-3) calc(3 *6deg) calc(4 *6deg),
    var(--bg-radial-color-1) calc(4 *6deg) calc(5 *6deg),
    var(--bg-radial-color-2) calc(5 *6deg) calc(6 *6deg),
    var(--bg-radial-color-1) calc(6 *6deg) calc(7 *6deg),
    var(--bg-radial-color-3) calc(7 *6deg) calc(8 *6deg),
    var(--bg-radial-color-1) calc(8 *6deg) calc(9 *6deg),
    var(--bg-radial-color-2) calc(9 *6deg) calc(10*6deg),
    var(--bg-radial-color-1) calc(10*6deg) calc(11*6deg),
    var(--bg-radial-color-0) calc(11*6deg) calc(12*6deg),
    var(--bg-radial-color-1) calc(12*6deg) calc(13*6deg),
    var(--bg-radial-color-2) calc(13*6deg) calc(14*6deg),
    var(--bg-radial-color-1) calc(14*6deg) calc(15*6deg),
    var(--bg-radial-color-3) calc(15*6deg) calc(16*6deg),
    var(--bg-radial-color-1) calc(16*6deg) calc(17*6deg),
    var(--bg-radial-color-2) calc(17*6deg) calc(18*6deg),
    var(--bg-radial-color-1) calc(18*6deg) calc(19*6deg),
    var(--bg-radial-color-3) calc(19*6deg) calc(20*6deg)
  );
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.opaque {
  background: rgba(255, 255, 255, 0.9);
}

.background-logo {
  background-image: url(images/logo.png);
  image-rendering: pixelated;
  background-size: contain;
  opacity: 1;
  width: 192px;
  height: 192px;
  position: fixed;
  top: 50%;
  left: 50%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
  transform: translate(calc(-50% + 3px), calc(-50% + 36px));
}

.center-fade {
  position: fixed;
  top: 50%;
  left: 50%;
  background: radial-gradient(
    rgba(255,255,255,1),
    rgba(255,255,255,0),
    rgba(255,255,255,0)
  );
  width: 128px;
  height: 128px;
  z-index: -2;
  transform: translate(-50%, -50%);
}

h1 {
  font-family: MaldiniStyle !important;
  font-size: 2.5rem;
  text-transform: uppercase;
}

h2 {
  font-family: Maldini;
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1.1;
  margin-bottom: 5px;
  letter-spacing: 0.2rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.spin {
  animation: spin infinite 8s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wallet-button {
  margin-bottom: 7px !important;
  width: 75%;
}

@font-face {
  font-family: Maldini;
  src: url("fonts/maldini/normal.otf") format("opentype");
}

@font-face {
  font-family: MaldiniBold;
  src: url("fonts/maldini/bold.otf") format("opentype");
}

@font-face {
  font-family: MaldiniStyle;
  src: url("fonts/maldini/style.otf") format("opentype");
}

.maldini {
  font-family: Maldini;
}

.maldini-bold {
  font-family: MaldiniBold !important;
}

.maldini-style {
  font-family: MaldiniStyle !important;
  font-weight: 300;
}

.bigtext {
  font-size: 1.4rem;
}

.SnackbarItem-contentRoot {
  color: var(--primary) !important;
  background: white !important;
  align-items: flex-start !important;
}

.SnackbarItem-variantWarning {
  color: orangered !important;
  font-weight: bold;
}

.SnackbarItem-variantError {
  color: red !important;
  font-weight: bold;
}

.SnackbarItem-action {
  padding-left: 8px !important;
}

.hidden {
  display: none;
}

.waiting-draw {
  position: fixed;
  top: 5px;
  left: 5px;
  padding: 12px;
  z-index: 3;
}

thead .MuiTableCell-root {
  position: relative;
}

.MuiTableCell-root {
  font-size: 1.1rem !important;
}

thead .MuiTableCell-root,
tbody .MuiTableCell-root {
  text-align: center;
}

tbody .MuiTableCell-root:first-child {
  text-align: left;
  white-space: nowrap;
}

.tl {
  text-align: left !important;
}

.float-bottom {
  position: absolute !important;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
  font-size: 11pt !important;
}

.iconsmall {
  font-size: 8pt !important;
}

.glow {
  text-shadow: 0 0 15px rgba(255,255,255,0.7), 0 0 20px rgba(255,255,255,0.7), 0 0 30px rgba(255,255,255,0.7), 0 0 40px rgba(255,255,255,0.7), 0 0.70px rgba(255,255,255,0.7), 0 0 60px rgba(255,255,255,0.7), 0 0 70px rgba(255,255,255,0.7);
}

.glow2 {
  text-shadow: 0 0 2px rgba(255,255,255,0.9), 0 0 10px rgba(255,255,255,0.9), 0 0 30px rgba(255,255,255,0.9), 0 0 40px rgba(255,255,255,0.9), 0 0 50px rgba(255,255,255,0.9), 0 0 60px rgba(255,255,255,0.9), 0 0 70px rgba(255,255,255,0.9);
}

.faq p {
  margin: 0;
}

.faq td, .faq th {
  border: 1px var(--primary) solid;
}

.faq table {
  align-self: center;
}

.txn-button, .txn-button:hover {
  border-width: 3px !important;
  font-size: 110% !important;
}

:root {
  --loader-outer: calc(min(50vh, 50vw));
  --loader-ball: 64px;
  --loader-tiny: 0.15;
  --loader-duration: 1.5s;
  --loader-color: var(--bg-radial-color-4);
}

/* Loader 5 */
.loader-5 {
	height: var(--loader-outer);
	width: var(--loader-outer);
	-webkit-animation: loader-5-1 var(--loader-duration)cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-5-1 var(--loader-duration)cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-5-1 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-5-1 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader-5::before {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: auto;
	margin: auto;
	width: var(--loader-ball);
	height: var(--loader-ball);
	background: var(--loader-color);
	border-radius: 50%;
	-webkit-animation: loader-5-2 var(--loader-duration)cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-5-2 var(--loader-duration)cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-5-2 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(24px, 0, 0) scale(var(--loader-tiny)); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes loader-5-2 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(24px, 0, 0) scale(var(--loader-tiny)); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}
.loader-5::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: auto;
	bottom: 0; right: 0;
	margin: auto;
	width: var(--loader-ball);
	height: var(--loader-ball);
	background: var(--loader-color);
	border-radius: 50%;
	-webkit-animation: loader-5-3 var(--loader-duration)cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-5-3 var(--loader-duration)cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-5-3 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(-24px, 0, 0) scale(var(--loader-tiny)); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes loader-5-3 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(-24px, 0, 0) scale(var(--loader-tiny)); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}
.loader-5 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: var(--loader-outer);
	width: var(--loader-outer);
}
.loader-5 span::before {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: auto; right: 0;
	margin: auto;
	width: var(--loader-ball);
	height: var(--loader-ball);
	background: var(--loader-color);
	border-radius: 50%;
	-webkit-animation: loader-5-4 var(--loader-duration)cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-5-4 var(--loader-duration)cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-5-4 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(0, 24px, 0) scale(var(--loader-tiny)); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes loader-5-4 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(0, 24px, 0) scale(var(--loader-tiny)); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}
.loader-5 span::after {
	content: "";
	display: block;
	position: absolute;
	top: auto; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	width: var(--loader-ball);
	height: var(--loader-ball);
	background: var(--loader-color);
	border-radius: 50%;
	-webkit-animation: loader-5-5 var(--loader-duration)cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-5-5 var(--loader-duration)cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-5-5 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(0, -24px, 0) scale(var(--loader-tiny)); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes loader-5-5 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(0, -24px, 0) scale(var(--loader-tiny)); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}

.center {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
}

.pera-wallet-connect-modal-touch-screen-mode__launch-pera-wallet-button {
  background-color: var(--primary) !important;
}

.pera-wallet-connect-modal-touch-screen-mode__launch-pera-wallet-button,
.pera-wallet-connect-modal-touch-screen-mode__install-pera-wallet-button {
  border-radius: 0 !important;
}

.pera-wallet-connect-modal h1 {
  font-family: var(--pera-wallet-connect-modal-font-family) !important;
}

.fakeButton:hover {
  cursor: pointer;
}

.bigflag {
  font-size: 2.5rem;
}

.minicupstake {
  height: 210px;
  overflow: hidden;
  position: relative;
  width: 400px;
}

.minicupstake img {
  position: absolute;
  bottom: 0;
  left: 0;
}

.minicupstake .disqualified {
  font-size: 3.5rem;
  font-family: maldinibold;
  position: absolute;
  bottom: 9px;
  left: 50%;
  transform: translate(-50%, -0%) rotate(-9deg);
  z-index: 2;
  opacity: 0.8;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  letter-spacing: 0.1rem;
}

.al {
  align-self: flex-start;
}

.mono {
  filter: grayscale(0.8) sepia(0.7);
}
.mono:hover {
  filter: grayscale(0.4);
}
