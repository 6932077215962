.cupstake {
  margin: 8px;
  width: 266px;
  height: 550px;
  background-color: transparent;
  perspective: 1000px;
}

.cupstake:hover {
  animation: slightzoom forwards 500ms ease-out;
}

.cupstake.burn:hover {
  animation: burn forwards 200ms;
}

.cupstake.short:hover {
  z-index: 3;
}

@keyframes slightzoom {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  to {
    transform: scale(1.1);
  }
}

.cupstake.short {
  margin-bottom: -318px;
  z-index: 2;
}

.cupstake.short .cupstake-inner {
  overflow: hidden;
}
.cupstake.burn .amount {
  display: none;
}
.amount {
  color: var(--primary);
  font-size: 1.75rem;
  position: absolute;
  top: 5px;
  left: 0px;
  background: rgba(255,255,255,0.7);
  aspect-ratio: 1/1;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-family: MaldiniBold;
  padding-top: 3px;
  border: 3px var(--primary) solid;
}

.amount.new {
  font-size: 1.5rem;
  padding-top: 4px;
}

.backflip {
  transform: rotateY(-180deg) scale(0.8);
}

.backflipb {
  animation-fill-mode: backwards;

}

.backflip1 {
  animation: backflip forwards 500ms linear;
}

.backflip2 {
  animation: backflip forwards 500ms linear 0.1s;
}

.backflip3 {
  animation: backflip forwards 500ms linear 0.2s;
}

@keyframes backflip {
  from {
    transform: rotateY(-180deg) scale(0.8);
  }
  to {
    transform: rotateY(360deg) scale(1);
  }
}
.rotating {
  animation: rotating infinite 3s linear
}

.antirotating {
  animation-direction: reverse;
}

.antirotating.stop-on-hover:hover,
.rotating.stop-on-hover:hover {
  animation: none;
  transform: rotateY(0deg) !important;
}

.scale08 {
  animation: scale08  forwards 0.3s ease-out;
}

@keyframes scale08 {
  from {
    transform: scale(1) rotateY(0deg);
  }
  to {
    transform: scale(0.8) rotateY(180deg);
  }
}

@keyframes rotating {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.cupstake-inner {
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  position: relative;
  width: 100%;
  height: 100%;
}

/*
.cupstake:hover .cupstake-inner {
  transform: rotateY(180deg);
}
*/

.cupstake .front, .cupstake .back {
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

.cupstake img {
  max-width: 100%;
}
.cupstake .back {
  transform: rotateY(180deg);
}

.burn {
  animation: burn forwards 1s;
}

@keyframes burn {
  from {
    filter: sepia(1) hue-rotate(0) saturate(1) brightness(1) blur(0px);
  }
  to {
    filter: sepia(1) hue-rotate(-37deg) saturate(3) brightness(1.5) blur(1px);
  }
}

