:root {
  --animation-time: 666ms;
  --half-animation-time: calc(var(--animation-time) / 2);
}

.zoom-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

}

.whitebg {
  background: white;
}

.maldinizoom {
  font-family: MaldiniStyle;
  color: var(--primary);
  text-align: center;
}

.zoom, .zoomfinal {
  font-size: 10pt;
  opacity: 0;
  animation: zoom forwards var(--animation-time) cubic-bezier(0.73, 0.06, 0.93, 0.13); 
  /* cubic-bezier(0.51, 0.14, 0.88, 0.44); */
  /* cubic-bezier(0.73, 0.06, 0.93, 0.13); */
}

.zoomfinal {
  animation: zoomfinal forwards var(--animation-time) cubic-bezier(0.73, 0.06, 0.93, 0.13); 
}

.finalsmall {
  font-size: 50%;
  display: flex;
  max-width: 100vw;
  flex-wrap: wrap;
  justify-content: center;
}

@keyframes zoom {
  from {
    transform: scale(0.1);
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.8;
  }
  to {
    font-size: 50rem;
    transform: scale(1.5);
  }
}

@keyframes zoomfinal {
  from {
    font-size: 0.5rem;
    line-height: 0.5rem;
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  to {
    font-size: 10rem;
    line-height: 8rem;
    opacity: 1;
  }
}


@keyframes zoomdis {
  from {
    font-size: 1pt;
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  95% {
    opacity: 0.9;
    font-size: 1000pt;
  }
  to {
    font-size: 2000pt;
    opacity: 0;
  }
}

@keyframes revzoom {
  from {
    font-size: 1000pt;
    opacity: 0;
  }
  10% {
    opacity: 0.9;
  }
  90% {
    opacity: 1;
  }
  to {
    font-size: 1pt;
    opacity: 0;
  }
}
