.delay-0 {
  animation-delay: calc(var(--half-animation-time) * 0);
}

.delay-1 {
  animation-delay: calc(var(--half-animation-time) * 1);
}

.delay-2 {
  animation-delay: calc(var(--half-animation-time) * 2);
}

.delay-3 {
  animation-delay: calc(var(--half-animation-time) * 3);
}

.delay-4 {
  animation-delay: calc(var(--half-animation-time) * 4);
}

.delay-5 {
  animation-delay: calc(var(--half-animation-time) * 5);
}

.delay-6 {
  animation-delay: calc(var(--half-animation-time) * 6);
}

.delay-7 {
  animation-delay: calc(var(--half-animation-time) * 7);
}

.delay-8 {
  animation-delay: calc(var(--half-animation-time) * 8);
}

.delay-9 {
  animation-delay: calc(var(--half-animation-time) * 9);
}

.delay-10 {
  animation-delay: calc(var(--half-animation-time) * 10);
}

.delay-11 {
  animation-delay: calc(var(--half-animation-time) * 11);
}

.delay-12 {
  animation-delay: calc(var(--half-animation-time) * 12);
}

.delay-13 {
  animation-delay: calc(var(--half-animation-time) * 13);
}

.delay-14 {
  animation-delay: calc(var(--half-animation-time) * 14);
}

.delay-15 {
  animation-delay: calc(var(--half-animation-time) * 15);
}

.delay-16 {
  animation-delay: calc(var(--half-animation-time) * 16);
}

.delay-17 {
  animation-delay: calc(var(--half-animation-time) * 17);
}

.delay-18 {
  animation-delay: calc(var(--half-animation-time) * 18);
}

.delay-19 {
  animation-delay: calc(var(--half-animation-time) * 19);
}

.delay-20 {
  animation-delay: calc(var(--half-animation-time) * 20);
}

.delay-21 {
  animation-delay: calc(var(--half-animation-time) * 21);
}

.delay-22 {
  animation-delay: calc(var(--half-animation-time) * 22);
}

.delay-23 {
  animation-delay: calc(var(--half-animation-time) * 23);
}

.delay-24 {
  animation-delay: calc(var(--half-animation-time) * 24);
}

.delay-25 {
  animation-delay: calc(var(--half-animation-time) * 25);
}

.delay-26 {
  animation-delay: calc(var(--half-animation-time) * 26);
}

.delay-27 {
  animation-delay: calc(var(--half-animation-time) * 27);
}

.delay-28 {
  animation-delay: calc(var(--half-animation-time) * 28);
}

.delay-29 {
  animation-delay: calc(var(--half-animation-time) * 29);
}

.delay-30 {
  animation-delay: calc(var(--half-animation-time) * 30);
}

.delay-31 {
  animation-delay: calc(var(--half-animation-time) * 31);
}

.delay-32 {
  animation-delay: calc(var(--half-animation-time) * 32);
}

.delay-33 {
  animation-delay: calc(var(--half-animation-time) * 33);
}

.delay-34 {
  animation-delay: calc(var(--half-animation-time) * 34);
}

.delay-35 {
  animation-delay: calc(var(--half-animation-time) * 35);
}

.delay-36 {
  animation-delay: calc(var(--half-animation-time) * 36);
}

.delay-37 {
  animation-delay: calc(var(--half-animation-time) * 37);
}

.delay-38 {
  animation-delay: calc(var(--half-animation-time) * 38);
}

.delay-39 {
  animation-delay: calc(var(--half-animation-time) * 39);
}

.delay-40 {
  animation-delay: calc(var(--half-animation-time) * 40);
}

.delay-41 {
  animation-delay: calc(var(--half-animation-time) * 41);
}

.delay-42 {
  animation-delay: calc(var(--half-animation-time) * 42);
}

.delay-43 {
  animation-delay: calc(var(--half-animation-time) * 43);
}

.delay-44 {
  animation-delay: calc(var(--half-animation-time) * 44);
}

.delay-45 {
  animation-delay: calc(var(--half-animation-time) * 45);
}

.delay-46 {
  animation-delay: calc(var(--half-animation-time) * 46);
}

.delay-47 {
  animation-delay: calc(var(--half-animation-time) * 47);
}

.delay-48 {
  animation-delay: calc(var(--half-animation-time) * 48);
}

.delay-49 {
  animation-delay: calc(var(--half-animation-time) * 49);
}

.delay-50 {
  animation-delay: calc(var(--half-animation-time) * 50);
}

.delay-51 {
  animation-delay: calc(var(--half-animation-time) * 51);
}

.delay-52 {
  animation-delay: calc(var(--half-animation-time) * 52);
}

.delay-53 {
  animation-delay: calc(var(--half-animation-time) * 53);
}

.delay-54 {
  animation-delay: calc(var(--half-animation-time) * 54);
}

.delay-55 {
  animation-delay: calc(var(--half-animation-time) * 55);
}

.delay-56 {
  animation-delay: calc(var(--half-animation-time) * 56);
}

.delay-57 {
  animation-delay: calc(var(--half-animation-time) * 57);
}

.delay-58 {
  animation-delay: calc(var(--half-animation-time) * 58);
}

.delay-59 {
  animation-delay: calc(var(--half-animation-time) * 59);
}

.delay-60 {
  animation-delay: calc(var(--half-animation-time) * 60);
}

.delay-61 {
  animation-delay: calc(var(--half-animation-time) * 61);
}

.delay-62 {
  animation-delay: calc(var(--half-animation-time) * 62);
}

.delay-63 {
  animation-delay: calc(var(--half-animation-time) * 63);
}
